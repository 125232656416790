/* eslint-disable no-nested-ternary */
import { List, ListSubheader } from '@material-ui/core'
import React, { memo, useMemo } from 'react'
import useIsInViewport from 'use-is-in-viewport'
import { TERVEL_PEOPLE_IMAGE_WIDTH } from '../moesia/StrongHardCodedSizes'
import { Images } from '../figma/helpers/imageHelper'
import { Texts } from '../figma/helpers/TextRepository'
import createKeyIndex from '../helpers/createKeyIndex'
import ExperimentalImageContainer from '../moesia/components/ExperimentalImageContainer'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import StyledBox from '../moesia/components/StyledBox'
import styled from 'styled-components'
import { developerList, projectBuildList } from 'src/interfaces/HomeDeveloperProjectSlideTexts'
import HomeSliderHorizontalDotsComponent from './HomeSliderHorizontalDotsComponent'
import Spacings from 'src/figma/tokens/Spacings'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { determineTransform } from 'src/helpers/HomeSliderComponentHelper'
import useLanguage from 'src/hooks/useLanguage'
import bhDots from 'src/figma/images/bhDots'
import ZIndex from 'src/figma/tokens/ZIndex'

const HomeSliderComponent: React.FC = () => {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 40 })
  const isMobile = useIsMobile()
  const language = useLanguage()
  const developerListItems = useMemo(() => developerList(), [])
  const projectListItems = useMemo(() => projectBuildList(), [])

  return (
    <StyledBox
      fullWidth
      ref={targetRef}
      direction={isMobile ? 'column' : 'row'}
      align="flex-start"
      justify="center"
      top={Spacings.large}
      bottom={Spacings.huge}
      gap={!isMobile && Spacings.large}
    >
      <StyledImageContainer
        $isMobile={isMobile}
        $imageIsOnTheLeft={true}
        $slideImageLeft={true}
        $isInViewPort={isInViewport}
        imageKey={Images.leftPhoto}
        minHeight
      />
      <StyledBox position="relative" alignSelf="center">
        <DeveloperList
          $isDeveloperList={true}
          $isMobile={isMobile}
          $isInViewport={isInViewport}
          subheader={
            <StlyedListSubheader disableSticky>
              <TextContainer textKey={Texts.HeaderHire} />
            </StlyedListSubheader>
          }
        >
          {!!developerListItems &&
            developerListItems.map((item, index) => (
              <DeveloperList $isDeveloperList={true} $isInViewport={isInViewport} $isMobile={isMobile} key={createKeyIndex(index, 'home-developer-list')}>
                {language === 'bg' && item.textKey !== Texts.Hire6 && <StyledTextContainer textKey={item.textKey} />}
                {language !== 'bg' && <StyledTextContainer textKey={item.textKey} />}
              </DeveloperList>
            ))}
        </DeveloperList>
      </StyledBox>
      {isMobile && <HomeSliderHorizontalDotsComponent />}
      {!isMobile && <StyledSVGContainer SVG={bhDots} />}
      <StyledImageContainer
        $isMobile={isMobile}
        $imageIsOnTheLeft={false}
        $slideImageLeft={false}
        $isInViewPort={isInViewport}
        imageKey={Images.rightPhoto}
        minHeight
      />
      <StyledBox position="relative" alignSelf="center">
        <DeveloperList
          $isDeveloperList={false}
          $isMobile={isMobile}
          $isInViewport={isInViewport}
          subheader={
            <StlyedListSubheader disableSticky>
              <TextContainer textKey={Texts.HeaderWeCan} />
            </StlyedListSubheader>
          }
        >
          {!!projectListItems &&
            projectListItems.map((item, index) => (
              <DeveloperList $isDeveloperList={false} $isInViewport={isInViewport} $isMobile={isMobile} key={createKeyIndex(index, 'home-we-build-list')}>
                <StyledTextContainer textKey={item.textKey} />
              </DeveloperList>
            ))}
        </DeveloperList>
      </StyledBox>
    </StyledBox>
  )
}

export default memo(HomeSliderComponent)

const StyledImageContainer = styled(ExperimentalImageContainer)<{
  $isMobile: boolean
  $imageIsOnTheLeft: boolean
  $slideImageLeft: boolean
  $isInViewPort: boolean | null
}>`
  display: ${({ $isMobile }) => $isMobile && 'none'};
  border-radius: ${({ $imageIsOnTheLeft }) =>
    $imageIsOnTheLeft
      ? `${BorderRadius.verySoft} ${BorderRadius.verySoft} ${BorderRadius.verySoft} ${BorderRadius.circle}`
      : `${BorderRadius.verySoft} ${BorderRadius.verySoft} ${BorderRadius.circle} ${BorderRadius.verySoft}`};
  transform: ${({ $slideImageLeft, $isInViewPort }) => determineTransform($slideImageLeft, $isInViewPort)};
  z-index: ${ZIndex.high};
  min-width: ${TERVEL_PEOPLE_IMAGE_WIDTH}px;
  width: 100%;
  transition: transform 1s ease-out;
  position: absolute;
  max-width: ${TERVEL_PEOPLE_IMAGE_WIDTH}px;
`

const DeveloperList = styled(List)<{ $isDeveloperList: boolean; $isMobile: boolean; $isInViewport: boolean | null }>`
  &&& {
    text-align: ${({ $isDeveloperList, $isMobile }) => (!$isMobile ? ($isDeveloperList ? 'right' : 'left') : 'center')};
    justify-content: ${({ $isDeveloperList, $isMobile }) => (!$isMobile ? ($isDeveloperList ? 'flex-end' : 'flex-start') : 'center')};
    opacity: ${({ $isInViewport, $isMobile }) => !$isMobile && ($isInViewport ? '100%' : 0)};
    padding: ${({ $isMobile }) => ($isMobile ? `${Spacings.min}` : 0)};
    transition: all 1s ease-in-out;
  }
`

const StlyedListSubheader = styled(ListSubheader)`
  margin-bottom: ${Spacings.medium};
`

const StyledTextContainer = styled(TextContainer)`
  padding: ${Spacings.minimum} 0;
`

const StyledSVGContainer = styled(SVGContainer)`
  align-self: center;
  margin-top: 20px;
  svg {
    width: 27px;
    height: 122px;
  }
`
