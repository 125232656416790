import landPageYouCanImg from '../images/landPageYouCan.png'
export const landPageYouCan = landPageYouCanImg
import landPageReachUsRightImg from '../images/landPageReachUsRight.png'
export const landPageReachUsRight = landPageReachUsRightImg
import landPageReachUsLeftImg from '../images/landPageReachUsLeft.png'
export const landPageReachUsLeft = landPageReachUsLeftImg
import leftPhotoImg from '../images/leftPhoto.png'
export const leftPhoto = leftPhotoImg
import rightPhotoImg from '../images/rightPhoto.png'
export const rightPhoto = rightPhotoImg
import sloganPhotoImg from '../images/sloganPhoto.png'
export const sloganPhoto = sloganPhotoImg
import elementLeftImg from '../images/elementLeft.png'
export const elementLeft = elementLeftImg
import elements1Img from '../images/elements1.png'
export const elements1 = elements1Img
import footerLeftImg from '../images/footerLeft.png'
export const footerLeft = footerLeftImg
import footerRightImg from '../images/footerRight.png'
export const footerRight = footerRightImg
import solPageWeCanPhotoImg from '../images/solPageWeCanPhoto.png'
export const solPageWeCanPhoto = solPageWeCanPhotoImg
import solPageHirePfotoImg from '../images/solPageHirePfoto.png'
export const solPageHirePfoto = solPageHirePfotoImg
import teamMemberIlianImg from '../images/teamMemberIlian.png'
export const teamMemberIlian = teamMemberIlianImg
import teamMemberVasilImg from '../images/teamMemberVasil.png'
export const teamMemberVasil = teamMemberVasilImg
import teamMember1Img from '../images/teamMember1.png'
export const teamMember1 = teamMember1Img
import teamMemberHristoImg from '../images/teamMemberHristo.png'
export const teamMemberHristo = teamMemberHristoImg
import teamMemberNikolaiImg from '../images/teamMemberNikolai.png'
export const teamMemberNikolai = teamMemberNikolaiImg
import teamMemberKudretImg from '../images/teamMemberKudret.png'
export const teamMemberKudret = teamMemberKudretImg
import teamMemberSinanImg from '../images/teamMemberSinan.png'
export const teamMemberSinan = teamMemberSinanImg
import teamMemberEmanuilImg from '../images/teamMemberEmanuil.png'
export const teamMemberEmanuil = teamMemberEmanuilImg
import teamMemberGalyaImg from '../images/teamMemberGalya.png'
export const teamMemberGalya = teamMemberGalyaImg
import underConstructionImageImg from '../images/underConstructionImage.png'
export const underConstructionImage = underConstructionImageImg
import errorPageImageImg from '../images/errorPageImage.png'
export const errorPageImage = errorPageImageImg
import compPageAboutUsImg from '../images/compPageAboutUs.png'
export const compPageAboutUs = compPageAboutUsImg
import buildingOurWebsitePhotoImg from '../images/buildingOurWebsitePhoto.png'
export const buildingOurWebsitePhoto = buildingOurWebsitePhotoImg
import lookingForInvestorsPhotoImg from '../images/lookingForInvestorsPhoto.png'
export const lookingForInvestorsPhoto = lookingForInvestorsPhotoImg
import followUsPhotoImg from '../images/followUsPhoto.png'
export const followUsPhoto = followUsPhotoImg
import newPositionsPhotoImg from '../images/newPositionsPhoto.png'
export const newPositionsPhoto = newPositionsPhotoImg
import referFriendPhotoImg from '../images/referFriendPhoto.png'
export const referFriendPhoto = referFriendPhotoImg
import teamBuildingPhotoImg from '../images/teamBuildingPhoto.png'
export const teamBuildingPhoto = teamBuildingPhotoImg
import buildingOurWebsitePhotoFlippedImg from '../images/buildingOurWebsitePhotoFlipped.png'
export const buildingOurWebsitePhotoFlipped = buildingOurWebsitePhotoFlippedImg
import newPositionsPhotoFlippedImg from '../images/newPositionsPhotoFlipped.png'
export const newPositionsPhotoFlipped = newPositionsPhotoFlippedImg
import lookingForInvestorsPhotoFlippedImg from '../images/lookingForInvestorsPhotoFlipped.png'
export const lookingForInvestorsPhotoFlipped = lookingForInvestorsPhotoFlippedImg
import followUsPhotoFlippedImg from '../images/followUsPhotoFlipped.png'
export const followUsPhotoFlipped = followUsPhotoFlippedImg
import referFriendPhotoFlippedImg from '../images/referFriendPhotoFlipped.png'
export const referFriendPhotoFlipped = referFriendPhotoFlippedImg
import teamBuildingPhotoFlippedImg from '../images/teamBuildingPhotoFlipped.png'
export const teamBuildingPhotoFlipped = teamBuildingPhotoFlippedImg
import hiddenImg from '../images/hidden.png'
export const hidden = hiddenImg
import swedenFlagImg from '../images/swedenFlag.png'
export const swedenFlag = swedenFlagImg
import bulgariaFlagImg from '../images/bulgariaFlag.png'
export const bulgariaFlag = bulgariaFlagImg
import ukFlagImg from '../images/ukFlag.png'
export const ukFlag = ukFlagImg
import teamMemberNadezhdaImg from '../images/teamMemberNadezhda.png'
export const teamMemberNadezhda = teamMemberNadezhdaImg
import ukFlagSmallImg from '../images/ukFlagSmall.png'
export const ukFlagSmall = ukFlagSmallImg
import bulgariaFlagSmallImg from '../images/bulgariaFlagSmall.png'
export const bulgariaFlagSmall = bulgariaFlagSmallImg
import swedenFlagSmallImg from '../images/swedenFlagSmall.png'
export const swedenFlagSmall = swedenFlagSmallImg
import sentMessageImageImg from '../images/sentMessageImage.png'
export const sentMessageImage = sentMessageImageImg
import noLogoImg from '../images/noLogo.png'
export const noLogo = noLogoImg
import noAvatarPhotoImg from '../images/noAvatarPhoto.png'
export const noAvatarPhoto = noAvatarPhotoImg
import solPageHowMediumImg from '../images/solPageHowMedium.png'
export const solPageHowMedium = solPageHowMediumImg
import dSolPageHowEndImg from '../images/dSolPageHowEnd.png'
export const dSolPageHowEnd = dSolPageHowEndImg
import teamMemberYuseinImg from '../images/teamMemberYusein.png'
export const teamMemberYusein = teamMemberYuseinImg
import teamMemberDianaImg from '../images/teamMemberDiana.png'
export const teamMemberDiana = teamMemberDianaImg
import flagUkSmallImg from '../images/flagUkSmall.png'
export const flagUkSmall = flagUkSmallImg
import solutionsPhoto1Img from '../images/solutionsPhoto1.png'
export const solutionsPhoto1 = solutionsPhoto1Img
import frame6Img from '../images/frame6.png'
export const frame6 = frame6Img
import solutionsPhoto2Img from '../images/solutionsPhoto2.png'
export const solutionsPhoto2 = solutionsPhoto2Img
import solutionsPhoto3Img from '../images/solutionsPhoto3.png'
export const solutionsPhoto3 = solutionsPhoto3Img
import solutionsPhotoImg from '../images/solutionsPhoto.png'
export const solutionsPhoto = solutionsPhotoImg
import contactPagePhotoImg from '../images/contactPagePhoto.png'
export const contactPagePhoto = contactPagePhotoImg
import somethingWentWrongPhotoImg from '../images/somethingWentWrongPhoto.png'
export const somethingWentWrongPhoto = somethingWentWrongPhotoImg
import contactUsFormErrorPhotoImg from '../images/contactUsFormErrorPhoto.png'
export const contactUsFormErrorPhoto = contactUsFormErrorPhotoImg
import mobCircleMenuOpenImg from '../images/mobCircleMenuOpen.png'
export const mobCircleMenuOpen = mobCircleMenuOpenImg
import underConstructionImgMobileImg from '../images/underConstructionImgMobile.png'
export const underConstructionImgMobile = underConstructionImgMobileImg
import errorPageImageMobileImg from '../images/errorPageImageMobile.png'
export const errorPageImageMobile = errorPageImageMobileImg
import flagUKhoverImg from '../images/flagUKhover.png'
export const flagUKhover = flagUKhoverImg
import iconMenuXImgImg from '../images/iconMenuXImg.png'
export const iconMenuXImg = iconMenuXImgImg
import mobileCenterCircleImg from '../images/mobileCenterCircle.png'
export const mobileCenterCircle = mobileCenterCircleImg
import contactPageBackgroundMobileImg from '../images/contactPageBackgroundMobile.png'
export const contactPageBackgroundMobile = contactPageBackgroundMobileImg
import contactPageMaskMobileImg from '../images/contactPageMaskMobile.png'
export const contactPageMaskMobile = contactPageMaskMobileImg
import mobileFooterImageImg from '../images/mobileFooterImage.png'
export const mobileFooterImage = mobileFooterImageImg
import mobElementImg from '../images/mobElement.png'
export const mobElement = mobElementImg
import mobCircleMenuImg from '../images/mobCircleMenu.png'
export const mobCircleMenu = mobCircleMenuImg
import sentMessageImageMobileImg from '../images/sentMessageImageMobile.png'
export const sentMessageImageMobile = sentMessageImageMobileImg
import somethingWentWrongPhotoMobileImg from '../images/somethingWentWrongPhotoMobile.png'
export const somethingWentWrongPhotoMobile = somethingWentWrongPhotoMobileImg
