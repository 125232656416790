import React, { memo } from 'react'
import StyledBox from '../moesia/components/StyledBox'
import { Images } from '../figma/helpers/imageHelper'
import { Texts } from '../figma/helpers/TextRepository'
import Shadows from '../figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import ExperimentalImageContainer from '../moesia/components/ExperimentalImageContainer'
import StyledLink from './StyledLink'
import TextContainer from '../moesia/components/TextContainer'
import styled from 'styled-components'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import mediaQueries from 'src/figma/tokens/MediaQueries'
import { JOBS_BG_LINK, LINE_HEIGHTS } from 'src/moesia/Constants'

const HomeJoinUsComponent: React.FC = () => {
  const isMobile = useIsMobile()
  return (
    <StyledBox fullWidth direction="row" justify="space-between" align="flex-end" top spacing={Spacings.big}>
      {!isMobile && <ExperimentalImageContainer imageKey={Images.landPageReachUsLeft} />}
      <TextBox alignText="center" alignSelf="center" isMobile={isMobile} bottom={Spacings.big} gap={Spacings.medium}>
        <StyledBox alignSelf="center" left right spacing={Spacings.medium} gap={Spacings.medium}>
          <TextContainer textKey={Texts.LandPageJoinOurTeam1} />
          <TextContainer textKey={Texts.LandPageJoinOurTeam2} />
        </StyledBox>
        <StyledLink
          href={JOBS_BG_LINK}
          openInNewTab
          alignSelf="center"
          width="fit-content"
          borderRadius={Spacings.big}
          boxShadow={Shadows.illustrationMedium}
          top={Spacings.small}
          left={Spacings.big}
          right={Spacings.big}
          bottom={Spacings.small}
          hoverEffect
          hoverBackgroundColor={FigmaColors.blueHover}
        >
          <TextContainer textKey={Texts.JoinUs} />
        </StyledLink>
      </TextBox>
      {!isMobile && <ExperimentalImageContainer imageKey={Images.landPageReachUsRight} />}
    </StyledBox>
  )
}

export default memo(HomeJoinUsComponent)

const TextBox = styled(StyledBox)<{ isMobile: boolean }>`
  & > div {
    @media (min-width: ${mediaQueries.md}px) and (max-width: ${mediaQueries.xl}px) {
      span {
        font-size: ${Spacings.medium} !important;
        line-height: ${LINE_HEIGHTS.medium} !important;
      }
    }
  }
`
