import { memo, useMemo } from 'react'
import styled from 'styled-components'
import getStaffMembers from '../constants/staffMembers'
import StyledBox from '../moesia/components/StyledBox'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from '../helpers/createKeyIndex'
import StyledLink from './StyledLink'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { REGULAR_TRANSITION } from 'src/moesia/Constants'
import linkedin from 'src/figma/images/linkedin'
import { sendGAEvent } from 'src/helpers/GoogleAnalyticsHelper'

const HomeReachUsSocialLinks = () => {
  const isMobile = useIsMobile()
  const staffMembers = useMemo(() => getStaffMembers(), [])

  return (
    <StyledBox direction={isMobile ? 'column' : 'row'} fullWidth fullPadding align="center" justify="center" gap={Spacings.medium}>
      <StyledBox
        direction={isMobile ? 'column' : 'row'}
        align={isMobile ? 'flex-start' : 'center'}
        gap={isMobile ? Spacings.large : Spacings.big}
        top
        bottom
        spacing={Spacings.big}
      >
        {!!staffMembers &&
          staffMembers.map(({ linkedInProfileText, linkedInProfile, googleAnalyticsEvent }, index) => (
            <StyledLink
              openInNewTab
              key={createKeyIndex(index, 'home-reach-us-wrapper')}
              href={linkedInProfile}
              onClick={() => googleAnalyticsEvent && sendGAEvent(googleAnalyticsEvent)}
            >
              <StyledLinksWrapper direction="row" align="center" justify="center" gap={Spacings.tiny}>
                <StyledSVGContainer SVG={linkedin} />
                <TextContainer textKey={linkedInProfileText} />
              </StyledLinksWrapper>
            </StyledLink>
          ))}
      </StyledBox>
    </StyledBox>
  )
}

export default memo(HomeReachUsSocialLinks)

const StyledLinksWrapper = styled(StyledBox)`
  transition: ${REGULAR_TRANSITION};
  color: ${FigmaColors.white};
  &:hover {
    svg {
      color: ${FigmaColors.bhBlue};
      background-color: ${FigmaColors.white};
    }
  }
`
const StyledSVGContainer = styled(SVGContainer)`
  svg {
    border-radius: ${BorderRadius.rounded};
  }
`
