import { BaseSyntheticEvent, FC, memo, SVGProps } from 'react'
import styled from 'styled-components'
import { Texts } from '../figma/helpers/TextRepository'
import BorderRadius from '../figma/tokens/BorderRadius'
import FigmaColors from '../figma/tokens/FigmaColors'
import MediaQueries from '../figma/tokens/MediaQueries'
import Spacings from '../figma/tokens/Spacings'
import StyledBox from '../moesia/components/StyledBox'
import SVGContainer from '../moesia/components/SVGContainer'
import TextContainer from '../moesia/components/TextContainer'
import { TextType } from '../moesia/interfaces/FigmaTypes'
import { TERVEL_CLIENT_CARD_HEIGHT } from '../moesia/StrongHardCodedSizes'
import StyledLink from './StyledLink'

type Props = {
  clientLogo: FC<SVGProps<SVGSVGElement>>
  centerText: TextType
  representativeName?: TextType
  clientWebsite?: string
  onClick: () => void
}

const ClientCardMobileComponent = ({ clientLogo, centerText, representativeName, clientWebsite, onClick }: Props) => {
  const handlePropagation = (event: BaseSyntheticEvent) => {
    event.stopPropagation()
  }

  return (
    <CardWrapper fullWidth onClick={onClick} justify="space-between" fullPadding>
      <StyledBox justify="space-between">
        <StyledBox fullWidth direction="row" justify="flex-end">
          <SVGContainer SVG={clientLogo} />
        </StyledBox>
        <CardMiddleTextContent right={Spacings.medium} top left spacing={Spacings.max} alignText="right">
          <TextContainer textKey={centerText} />
        </CardMiddleTextContent>
      </StyledBox>
      {representativeName && (
        <BottomCardContent fullWidth align="flex-start" left bottom spacing={Spacings.large}>
          <TextContainer textKey={representativeName} />
        </BottomCardContent>
      )}
      {clientWebsite && (
        <BottomCardContent fullWidth align="flex-start" left bottom spacing={Spacings.large}>
          <StyledLink onClick={handlePropagation} href={clientWebsite} openInNewTab>
            <TextContainer textKey={Texts.ViewMore} />
          </StyledLink>
        </BottomCardContent>
      )}
    </CardWrapper>
  )
}

export default memo(ClientCardMobileComponent)

const CardWrapper = styled(StyledBox)`
  height: ${TERVEL_CLIENT_CARD_HEIGHT};
  background-color: ${FigmaColors.white};
  border-radius: ${BorderRadius.soft} ${BorderRadius.soft} ${BorderRadius.circle} ${BorderRadius.soft};
`

const CardMiddleTextContent = styled(StyledBox)`
  @media (max-width: ${MediaQueries.sm}px) {
    padding-top: ${Spacings.medium};
    padding-left: ${Spacings.big};
  }

  @media (max-width: ${MediaQueries.xs}px) {
    padding-top: 0;
    padding-left: 0;
  }
`

const BottomCardContent = styled(StyledBox)`
  @media (max-width: ${MediaQueries.xs}px) {
    padding-left: 0;
    padding-bottom: 0;
  }
`
