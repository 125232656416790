import { Texts } from '../figma/helpers/TextRepository'
import { ClientTypes } from './ClientTypes'

import binogiLogo from '../figma/images/binogiLogo'
import obpLoggo from '../figma/images/obpLoggo'
import myntLogo from 'src/figma/images/myntLogo'

export const clients: ClientTypes[] = [
  {
    clientLogo: myntLogo,
    clientTestimonyText: Texts.LandPageMyntTestem,
    clientDescription: Texts.LandPageMyntBack,
    clientRepresentativeName: Texts.LandPageMyntTestemCTO,
    clientWebsite: 'https://mynt.com/'
  },
  {
    clientLogo: binogiLogo,
    clientTestimonyText: Texts.LandPageBinogiTestem,
    clientDescription: Texts.LandPageBinogiBack,
    clientRepresentativeName: Texts.LandPageBinogiTestemCTO,
    clientWebsite: 'https://www.binogi.se/'
  },
  {
    clientLogo: obpLoggo,
    clientTestimonyText: Texts.LandPageBigpictureTestem,
    clientDescription: Texts.LandPageBigPictureBack,
    clientRepresentativeName: Texts.LandPageBigPictureTestemEduardo,
    clientWebsite: 'https://www.ourbigpicture.se/'
  }
]
