import Slide from '@mui/material/Slide'
import React, { useCallback, useState } from 'react'
import { Texts } from 'src/figma/helpers/TextRepository'
import mobileNavigationCloseIcon from 'src/figma/images/mobileNavigationCloseIcon'
import mobileNavigationMenuIcon from 'src/figma/images/mobileNavigationMenuIcon'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import Shadows from '../figma/tokens/Shadows'
import Spacings from 'src/figma/tokens/Spacings'
import StyledBox from 'src/moesia/components/StyledBox'
import SVGContainer from 'src/moesia/components/SVGContainer'
import TextContainer from 'src/moesia/components/TextContainer'
import { TERVEL_NAVIGATION_HEIGHT } from 'src/moesia/StrongHardCodedSizes'
import styled from 'styled-components'
import DevHarbourLogo from './DevHarbourLogo'
import MobileNavigationMenu from './MobileNavigationMenu'
import ZIndex from 'src/figma/tokens/ZIndex'

const MobileNavigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleMenuOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <Wrapper fullWidth>
      <NavigationWrapper fullWidth fullPadding direction="row" spacing={Spacings.min} justify="space-between">
        <StyledBox direction="row" gap={Spacings.tiny} align="center" justify="center">
          <StyledDevHarbourLogo />
          <TextContainer textKey={Texts.MobileNavigationTitle} />
        </StyledBox>
        <StyledBox onClick={handleMenuOpen} right={Spacings.minimum} justify="center">
          <SVGContainer SVG={isOpen ? mobileNavigationCloseIcon : mobileNavigationMenuIcon} />
        </StyledBox>
      </NavigationWrapper>
      <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
        <MenuWrapper fullWidth fullPadding spacing={Spacings.small} justify="space-between">
          <MobileNavigationMenu />
        </MenuWrapper>
      </Slide>
    </Wrapper>
  )
}
export default React.memo(MobileNavigation)

const NavigationWrapper = styled(StyledBox)`
  height: ${TERVEL_NAVIGATION_HEIGHT};
  z-index: ${ZIndex.high};
  background-color: ${FigmaColors.white};
  box-shadow: ${Shadows.baseMedium};
`

const StyledDevHarbourLogo = styled(DevHarbourLogo)`
  height: 100%;
  span {
    height: 100% !important;
  }
`

const MenuWrapper = styled(StyledBox)`
  height: calc(100vh - (${TERVEL_NAVIGATION_HEIGHT}));
`
const Wrapper = styled(StyledBox)`
  * {
    box-sizing: border-box;
  }
`
