import React, { memo, useMemo } from 'react'
import Spacings from 'src/figma/tokens/Spacings'
import createKeyIndex from '../helpers/createKeyIndex'
import TextContainer from '../moesia/components/TextContainer'
import StyledBox from '../moesia/components/StyledBox'
import styled from 'styled-components'
import { homePageHeaderTextsDesktop, homePageHeaderTextsMobile } from 'src/interfaces/HomePageHeaderTexts'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import { LINE_HEIGHTS } from 'src/moesia/Constants'
import MediaQueries from 'src/figma/tokens/MediaQueries'

const HomeHeaderTextComponent: React.FC = () => {
  const isMobile = useIsMobile()
  const textsDesktop = useMemo(() => homePageHeaderTextsDesktop(), [])
  const textsMobile = useMemo(() => homePageHeaderTextsMobile(), [])

  return (
    <Wrapper>
      {!isMobile && (
        <StyledBox alignSelf="center" alignText="center" justifySelf="center" left right spacing={Spacings.huge}>
          {!!textsDesktop && textsDesktop.map(({ textKey }, index) => <TextContainer textKey={textKey} key={createKeyIndex(index, 'home-center-item')} />)}
        </StyledBox>
      )}
      {isMobile && (
        <StyledBox>
          {!!textsMobile && textsMobile.map(({ textKey }, index) => <TextContainer textKey={textKey} key={createKeyIndex(index, 'home-center-item')} />)}
        </StyledBox>
      )}
    </Wrapper>
  )
}

export default memo(HomeHeaderTextComponent)

const Wrapper = styled(StyledBox)`
  max-width: 55%;
  @media (min-width: ${MediaQueries.md}px) and (max-width: ${MediaQueries.lg}px) {
    span {
      font-size: ${Spacings.medium} !important;
      line-height: ${LINE_HEIGHTS.big} !important;
    }
  }
`
