import React, { memo } from 'react'
import { Texts } from '../figma/helpers/TextRepository'
import TextContainer from '../moesia/components/TextContainer'
import image from '../figma/images/landPageYouCan.png'
import StyledBox from 'src/figma/components/StyledBox'
import styled from 'styled-components'
import Spacings from 'src/figma/tokens/Spacings'
import StyledLink from './StyledLink'
import HomeReachUsSocialLinks from './HomeReachUsSocialLinks'
import BorderRadius from 'src/figma/tokens/BorderRadius'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import { sendGAEvent } from 'src/helpers/GoogleAnalyticsHelper'
import { GoogleAnalyticsEvents } from 'src/enums/GoogleAnalyticsEnums'

const HomeReachUsComponent: React.FC = () => (
  <Wrapper fullWidth align="center" top bottom spacing={Spacings.max}>
    <StyledBox alignText="center" alignSelf="center" top={Spacings.big} bottom={Spacings.big} left={Spacings.small} right={Spacings.small}>
      <TextContainer textKey={Texts.YouCanReachUs} />
    </StyledBox>
    <EmailBox direction="row" align="center" alignSelf="center" fullPadding={Spacings.minimum} gap={Spacings.min}>
      <TextContainer textKey={Texts.Email} />
      <StyledLink href={'mailto:office@dev-harbour.com'} onClick={() => sendGAEvent(GoogleAnalyticsEvents.CLICK_HOMEPAGE_EMAIL_OFFICE)}>
        <StyledTextContainer textKey={Texts['link@BHStudio.com']} />
      </StyledLink>
    </EmailBox>
    <HomeReachUsSocialLinks />
  </Wrapper>
)

export default memo(HomeReachUsComponent)

const Wrapper = styled(StyledBox)`
  background-image: url(${image});
  background-position: top;
  background-size: cover;
`

const EmailBox = styled(StyledBox)`
  background-color: ${FigmaColors.stone};
  border-radius: ${BorderRadius.circle};
`

const StyledTextContainer = styled(TextContainer)`
  && span {
    color: ${FigmaColors.bhBlue} !important;
  }
`
