import 'core-js/stable'
import React from 'react'
import Router from './Router'
import { HelmetProvider } from 'react-helmet-async'
import { Context, useAppState } from './hooks/useAppState'
import HelmetMaterialUIIcons from './helpers/HelmetMaterialUIIcons'
import useGA4Tracker from './hooks/useGA4Tracker'

const App: React.FC = () => {
  const { state, setState } = useAppState()
  useGA4Tracker()

  return (
    <HelmetProvider>
      <Context.Provider value={{ state, setState }}>
        <HelmetMaterialUIIcons />
        <Router />
      </Context.Provider>
    </HelmetProvider>
  )
}
export default App
