export const defaultConfiguration = {
  baseUrl: '',
  gaTrackID: 'G-N1CBCF84K2',
  ga4MeasurementID: 'G-61VMJL1WW6',
  enabledCardAnimation: false,
  enabledRegister: false,
  enabledCookies: false,
  enabledSandbox: false,
  enabledLanguageBar: true,
  enableLinkedInLink: true
}

const local = {
  ...defaultConfiguration,
  name: 'local',
  ga4MeasurementID: 'G-6KHEFXBL5X',
  enabledCardAnimation: true,
  enabledRegister: true,
  enabledLanguageBar: true
} as typeof defaultConfiguration

export const development = {
  ...defaultConfiguration,
  name: 'development',
  ga4MeasurementID: 'G-6KHEFXBL5X',
  enabledCardAnimation: true,
  enabledCookies: false,
  enabledLanguageBar: true
} as typeof defaultConfiguration

export const production = {
  ...defaultConfiguration,
  name: 'production',
  baseUrl: '',
  gaTrackID: 'G-61VMJL1WW6',
  ga4MeasurementID: 'G-61VMJL1WW6'
} as typeof defaultConfiguration

export default {
  local,
  development,
  production
} as { [key: string]: typeof defaultConfiguration }

export const DEFAULT_ENV = 'production'

export type Config = typeof defaultConfiguration
