import React, { memo } from 'react'
import styled from 'styled-components'
import NavLinkButton from './NavLinkButton'
import routePaths from '../config/RoutePaths'
import Spacings from 'src/figma/tokens/Spacings'
import { Images } from '../figma/helpers/imageHelper'
import StyledBox from '../moesia/components/StyledBox'
import { Texts } from '../figma/helpers/TextRepository'
import useIsMobile from 'src/moesia/hooks/useIsMobile'
import NavigationMotion from './NavigationMotion'
import TextContainer from '../moesia/components/TextContainer'
import ExperimentalImageContainer from '../moesia/components/ExperimentalImageContainer'

const Error404Mobile: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <Wrapper fullWidth>
      <NavigationMotion>
        <StyledBox fullWidth position="relative" align="center" top={isMobile ? Spacings.huge : Spacings.min}>
          <StyledBox fullWidth align="center">
            <ExperimentalImageContainer imageKey={isMobile ? Images.errorPageImageMobile : Images.errorPageImage} />
            <StyledNavLinkButton to={routePaths.entry} $isMobile={isMobile}>
              <StyledBox align="center" justify="center">
                <TextContainer textKey={Texts.GoHomeButtonText} />
              </StyledBox>
            </StyledNavLinkButton>
          </StyledBox>
        </StyledBox>
      </NavigationMotion>
    </Wrapper>
  )
}

export default memo(Error404Mobile)

const Wrapper = styled(StyledBox)`
  height: 100vh;
`

const StyledNavLinkButton = styled(NavLinkButton)<{ $isMobile: boolean }>`
  width: fit-content;
  margin-top: ${({ $isMobile }) => $isMobile && Spacings.large};
  text-align: center;
`
