import CircularProgress from '@mui/material/CircularProgress'
import React, { MouseEventHandler } from 'react'
import FigmaColors from 'src/figma/tokens/FigmaColors'
import { DefaultButton, PrimaryButton, SecondaryButton, TertiaryButton } from 'src/moesia/helpers/SubmitButtonVariants'
import StyledBox from 'src/moesia/components/StyledBox'
import { TextType } from 'src/moesia/interfaces/FigmaTypes'
import styled from 'styled-components'

export type Variant = 'primary' | 'secondary' | 'tertiary'
type Props = {
  variant?: Variant
  textKey: TextType
  text?: string
  onClick?: MouseEventHandler<HTMLButtonElement> | (() => void)
  loading?: boolean
  disabled?: boolean
}

const SubmitButton: React.FC<Props> = ({ variant, textKey, text, onClick, loading, disabled }) => {
  const element = getButtonElement({ variant })

  return (
    <StyledBox onClick={onClick}>
      {!!loading && (
        <LoaderWrapper direction="row" justify="center">
          <CircularProgress />
        </LoaderWrapper>
      )}
      {!loading && React.createElement(element, { textKey, text, disabled })}
    </StyledBox>
  )
}

export default SubmitButton

const LoaderWrapper = styled(StyledBox)`
  .MuiCircularProgress-circle {
    color: ${FigmaColors.bhBlue};
  }
`

const getButtonElement = ({ variant }: { variant?: Variant }) => {
  if (variant === 'primary') return PrimaryButton
  if (variant === 'secondary') return SecondaryButton
  if (variant === 'tertiary') return TertiaryButton

  return DefaultButton
}
