import React, { memo } from 'react'
import { Texts } from '../figma/helpers/TextRepository'
import SectionWrapper from './SectionWrapper'
import TextContainer from '../moesia/components/TextContainer'
import StyledBox from '../moesia/components/StyledBox'
import styled from 'styled-components'
import HomeClientCardsCarousel from './HomeClientCardsCarousel'
import Spacings from 'src/figma/tokens/Spacings'
import FigmaColors from 'src/figma/tokens/FigmaColors'

const HomeClientsComponent: React.FC = () => (
  <StyledWrapperBox fullWidth position="relative" top spacing={Spacings.medium} alignText="center">
    <SectionWrapper align="center">
      <TextContainer textKey={Texts.LandPageWhyShould} />
    </SectionWrapper>
    <TextContainer textKey={Texts.LandPageHereAre} />
    <StyledBox fullWidth bottom={Spacings.max}>
      <HomeClientCardsCarousel />
    </StyledBox>
  </StyledWrapperBox>
)

export default memo(HomeClientsComponent)

const StyledWrapperBox = styled(StyledBox)`
  background-color: ${FigmaColors.bhBlueDark};
`
