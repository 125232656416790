import { memo } from 'react'
import StyledBox from '../moesia/components/StyledBox'
import styled from 'styled-components'
import { TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE } from '../moesia/StrongHardCodedSizes'
import { ClientTypes } from '../interfaces/ClientTypes'
import ClientCardComponent from './ClientCardComponent'

type Props = {
  clientCard: ClientTypes
}

const AnimatedClientCard = ({ clientCard }: Props) => {
  const { clientLogo, clientTestimonyText, clientRepresentativeName, clientDescription, clientWebsite } = clientCard

  return (
    <FlipCard>
      <FlipCardInner position="relative">
        <ClientCardComponent clientLogo={clientLogo} centerText={clientTestimonyText} representativeName={clientRepresentativeName} />
        <ClientCardComponent clientLogo={clientLogo} centerText={clientDescription} clientWebsite={clientWebsite} />
      </FlipCardInner>
    </FlipCard>
  )
}

export default memo(AnimatedClientCard)

const FlipCard = styled(StyledBox)`
  // Used for the 3D effect when flipping the card.
  perspective: ${TERVEL_NEWS_CARD_FLIP_CARD_PERSPECTIVE};
`

const FlipCardInner = styled(StyledBox)`
  transition: transform 0.8s;
  :hover {
    transform: rotateY(180deg);
  }
  transform-style: preserve-3d;
`
