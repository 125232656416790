import App from './App'
import theme from './theme'
import ReactDOM from 'react-dom/client'
import ErrorBoundary from './components/ErrorBoundary'
import { ThemeProvider } from '@material-ui/core/styles'

const root = document.getElementById('root')

if (!!root) {
  ReactDOM.createRoot(root).render(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ErrorBoundary>
  )
}
