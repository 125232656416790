import { GoogleAnalyticsEvents } from 'src/enums/GoogleAnalyticsEnums'
import { DEV_HARBOUR_LINKEDIN, HRISTO_STAMENOV_LINKEDIN, VASSIL_NIKOLOV_LINKEDIN } from 'src/moesia/Constants'
import { Texts } from '../figma/helpers/TextRepository'
import { StaffMemberTypes } from '../interfaces/StaffMemberTypes'

const getStaffMembers = (): StaffMemberTypes[] => [
  {
    linkedInProfileText: Texts.LandPageTeamMember2,
    linkedInProfile: DEV_HARBOUR_LINKEDIN,
    googleAnalyticsEvent: GoogleAnalyticsEvents.CLICK_HOMEPAGE_DEVHARBOUR_LINKEDIN
  },
  {
    linkedInProfileText: Texts.LandPageTeamMember1,
    linkedInProfile: HRISTO_STAMENOV_LINKEDIN
  },
  {
    linkedInProfileText: Texts.LandPageTeamMember3,
    linkedInProfile: VASSIL_NIKOLOV_LINKEDIN
  }
]

export default getStaffMembers
